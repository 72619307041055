import React from 'react';

import Header from './components/Header'
import Products from './components/Products'
import Comments from './components/Comments'
import SliderComponent from './components/SliderComponent'
import WhatsButton from './components/WhatsButton';
import Footer from './components/Footer'

function App() {
  return (
    <>
      <Header />
      <SliderComponent />
      <Products />
      <Comments />
      <WhatsButton />
      <Footer />
    </>
  );
}

export default App;
