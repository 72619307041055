import React, { useState, useEffect } from 'react';
import styles from '../styles.module.scss';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { data } from '../products';

const Vapesoul = () => {
    const [list, setList] = useState([]);

    useEffect(() => {
        const vapesoulData = data.find(item => item.mark === 'VAPESOUL');

        if (vapesoulData) {
            setList(vapesoulData.products);
        }
    }, []);

    return (
        <section className={styles.products}>
            <div>
                <h2>VAPESOUL</h2>
            </div>
            <>
                <Carousel
                    showArrows={true}
                    showStatus={false}
                    showThumbs={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={4000}
                    className={styles.commentCarousel}
                    showIndicators={false}
                >
                    {list.map((item, index) => (
                        <div key={index} className={styles.boxItem}>
                            <p>{item.name}</p>
                            <img src={item.image} alt="pod" />
                            <p>R${item.price.toFixed(2)}</p>
                        </div>
                    ))}
                </Carousel>
            </>
        </section>
    );
};

export default Vapesoul;
