import React from 'react';

import ImgCarousel1 from '../../assets/images/carousel-1.png'
import ImgCarousel2 from '../../assets/images/carousel-2.png'
import ImgCarousel3 from '../../assets/images/carousel-3.png'
import ImgCarousel4 from '../../assets/images/carousel-4.png'
import ImgCarousel5 from '../../assets/images/carousel-5.png'
import ImgCarousel6 from '../../assets/images/carousel-6.png'

import { UncontrolledCarousel } from 'reactstrap';

const SliderComponent = () => {

    return (
        <UncontrolledCarousel
            keyboard={false}
            items={[
                {
                    altText: '',
                    caption: '',
                    key: 0,
                    src: ImgCarousel1
                },
                {
                    altText: '',
                    caption: '',
                    key: 1,
                    src: ImgCarousel2
                },
                {
                    altText: '',
                    caption: '',
                    key: 2,
                    src: ImgCarousel3
                },
                {
                    altText: '',
                    caption: '',
                    key: 3,
                    src: ImgCarousel4
                },
                {
                    altText: '',
                    caption: '',
                    key: 4,
                    src: ImgCarousel5
                },
                {
                    altText: '',
                    caption: '',
                    key: 5,
                    src: ImgCarousel6
                }
            ]}
        />
    );
};

export default SliderComponent;
