import React from 'react';
import styles from './styles.module.scss';

import Ignite from './Ignite'
import Nikbar from './Nikbar';
import Maskking from './Maskking';
import Blvk from './Blvk';
import Elfbar from './Elfbar';
import Lowit from './Lowit';
import LostMary from './LostMary';
import Elfworld from './Elfworld';
import BlackSheep from './BlackSheep';
import RandmTornado from './RandmTornado';
import Hqd from './Hqd';
import Waka from './Waka';
import Vhazy from './Vhazy';
import Vapesoul from './Vapesoul';
import Linvo from './Linvo';
import Oxbar from './Oxbar';

const Products = () => {
    return (
        <div className={styles.productsContainer}>
            <Ignite />
            <Nikbar />
            <Maskking />
            <Blvk />
            <Elfbar />
            <Lowit />
            <LostMary />
            <Elfworld />
            <BlackSheep />
            <RandmTornado />
            <Hqd />
            <Waka />
            <Vhazy />
            <Vapesoul />
            <Linvo />
            <Oxbar />
        </div>
    );
};

export default Products;