import React from 'react';
import styles from './styles.module.scss'

import Instagram from '../../assets/images/instagram.svg'
import LogoWhats from '../../assets/images/whatsapp.svg'
import Logo from '../../assets/images/logo.jpg'

const Footer = () => {
    return (
        <section className={styles.footer}>
            <section>
                <img className={styles.logoFooter} src={Logo} alt="logo" width={150} />
            </section>
            <section>
                <div className={styles.contactInfo}>
                    <p>Entre em contato conosco:</p>
                    <p>21 96769-6531</p>
                </div>
                <div className={styles.boxSocial}>

                    <a target="__blank" href="https://www.instagram.com/podszonasul2/" rel="noopener noreferrer">
                        <img src={Instagram} width={30} alt="Instagram" />
                    </a>
                    <a target="__blank" className={styles.boxWhats} href="https://api.whatsapp.com/send?phone=5521967696531">
                        <img src={LogoWhats} width={30} alt="Logo Whatsapp" />
                    </a>
                </div>
            </section>
            <section>
                <div className={styles.contactInfo}>
                    <p>Delivery:</p>
                    <p>Segunda á Sábado</p>
                    <p>*Frete calculado de acordo com a região de entrega.</p>
                </div>

            </section>
        </section>
    );
};

export default Footer;