import React from 'react';

import LogoWhats from '../../assets/images/whatsapp.svg'
import Logo from '../../assets/images/logo.jpg'

import styles from './styles.module.scss';

function Header() {
    return (
        <>
            <div className={styles.boxSubmenu}>
                <a target="__blank" className={styles.boxWhats} href="https://api.whatsapp.com/send?phone=5521967696531">
                    <img src={Logo} alt="logo" className={styles.headerLogo} />
                </a>
                <div>
                </div>
                <p>Os Melhores Preços do Rio de Janeiro</p>
                <div>
                    <a target="__blank" className={styles.boxWhats} href="https://api.whatsapp.com/send?phone=5521967696531">
                        <img src={LogoWhats} alt="Logo Whatsapp" />
                        <p>Whatsapp: 21 96769-6531</p>
                    </a>
                </div>
            </div>
        </>
    );
}

export default Header;