export const data = [
    {
        mark: "IGNITE",
        products: [
            {
                name: "V15 1500 PUFFS",
                price: 79.00,
                priceOnCard: 85.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
            {
                name: "V25 2500 PUFFS",
                price: 90.00,
                priceOnCard: 97.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
            {
                name: "V40 4000 PUFFS",
                price: 100.00,
                priceOnCard: 105.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
            {
                name: "V50 5000 PUFFS",
                price: 112.00,
                priceOnCard: 118.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
        ],
    },
    {
        mark: "NIKBAR",
        products: [
            {
                name: "4000 PUFFS",
                price: 91.00,
                priceOnCard: 96.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
            {
                name: "8000 PUFFS",
                price: 110.00,
                priceOnCard: 115.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
        ],
    },
    {
        mark: "MASKKING",
        products: [
            {
                name: "450 PUFFS",
                price: 42.00,
                priceOnCard: 47.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
            {
                name: "5000 PUFFS",
                price: 91.00,
                priceOnCard: 96.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
        ],
    },
    {
        mark: "BLVK",
        products: [
            {
                name: "2500 PUFFS",
                price: 50.00,
                priceOnCard: 55.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
        ],
    },
    {
        mark: "ELFBAR",
        products: [
            {
                name: "BC4000 PUFFS",
                price: 96.00,
                priceOnCard: 101.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
            {
                name: "BC5000 PUFFS",
                price: 107.00,
                priceOnCard: 112.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
            {
                name: "TE5000 PUFFS",
                price: 93.00,
                priceOnCard: 99.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
            {
                name: "TE6000 PUFFS",
                price: 98.00,
                priceOnCard: 105.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
            {
                name: "FUNKY 7000 PUFFS",
                price: 105.00,
                priceOnCard: 112.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
        ],
    },
    {
        mark: "LOWIT",
        products: [
            {
                name: "5500 PUFFS (COM BATERIA)",
                price: 136.00,
                priceOnCard: 141.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
            {
                name: "(REFIL OU BATERIA)",
                price: 79.00,
                priceOnCard: 84.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
        ],
    },
    {
        mark: "LOST MARY",
        products: [
            {
                name: "OS5000 PUFFS",
                price: 90.00,
                priceOnCard: 96.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
        ],
    },
    {
        mark: "ELFWORLD",
        products: [
            {
                name: "DC5000",
                price: 72.00,
                priceOnCard: 79.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
            {
                name: "7000 PUFFS",
                price: 86.00,
                priceOnCard: 92.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
            {
                name: "10000 PUFFS",
                price: 108.00,
                priceOnCard: 115.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
        ],
    },
    {
        mark: "BLACK SHEEP",
        products: [
            {
                name: "600 PUFFS",
                price: 48.00,
                priceOnCard: 53.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
        ],
    },
    {
        mark: "RANDM TORNADO",
        products: [
            {
                name: "7000 PUFFS",
                price: 90.00,
                priceOnCard: 98.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
        ],
    },
    {
        mark: "HQD",
        products: [
            {
                name: "5500 PUFFS",
                price: 84.00,
                priceOnCard: 91.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
            {
                name: "6000 PUFFS",
                price: 89.00,
                priceOnCard: 95.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
        ],
    },
    {
        mark: "WAKA",
        products: [
            {
                name: "6000 PUFFS",
                price: 92.00,
                priceOnCard: 98.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg"
            },
            {
                name: "10000 PUFFS",
                price: 130.00,
                priceOnCard: 136.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg"
            },
        ]
    },
    {
        mark: "V-HAZY",
        products: [
            {
                name: "1500 PUFFS",
                price: 42.00,
                priceOnCard: 47.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
            {
                name: "1500 PUFFS (2 unidades)",
                price: 55.00,
                priceOnCard: 60.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
        ],
    },
    {
        mark: "VAPESOUL",
        products: [
            {
                name: "12000 PUFFS",
                price: 126.00,
                priceOnCard: 133.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
        ],
    },
    {
        mark: "LINVO",
        products: [
            {
                name: "10000 PUFFS",
                price: 110.00,
                priceOnCard: 115.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
        ],
    },
    {
        mark: "OXBAR",
        products: [
            {
                name: "8000 PUFFS",
                price: 109.00,
                priceOnCard: 115.00,
                image: "https://www.wolfshopbrasil.com/wp-content/uploads/2023/07/ignite_v50_5000_puffs_pod_descartavel_650_mah_411_1_484a4a7279425401c8a337137f903023.jpg",
            },
        ],
    },
];
