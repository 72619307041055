import React from 'react';

import LogoWhats from '../../assets/images/whatsapp.svg'

import styles from './styles.module.scss';

const WhatsButton = () => {
    return (
        <div className={styles.container}>
            <a target="__blank" className={styles.boxWhats} href="https://api.whatsapp.com/send?phone=5521967696531">
                <img src={LogoWhats} alt="Logo Whatsapp" />
            </a>
        </div>
    )
}

export default WhatsButton;