import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from './styles.module.scss';

const comments = [
    {
        name: 'Danielle Alcantara',
        comment: 'Excelente produto!'
    },
    {
        name: 'Pedro Pacheco',
        comment: 'Excelente produto, estou muito satisfeito!'
    },
    {
        name: 'Cícero Matos',
        comment: 'Produto muito bom.'
    },
    {
        name: 'Arthu Aguiar',
        comment: 'Recomendo a todos, ótimo atendimento!'
    },
    {
        name: 'Leo Macare',
        comment: 'Entrega rápida e produto de qualidade.'
    },
    {
        name: '	Graziele Silva',
        comment: 'Gostei muito, recomendo.'
    },
    {
        name: 'Felipe Rodrigues',
        comment: 'Gostei do produto, ótima qualidade.'
    },
    {
        name: 'Maria Valeria',
        comment: 'O melhor do RJ!'
    },
    {
        name: 'Miguel Carvalho',
        comment: 'Excelente preço.'
    },
    {
        name: 'Andressa Silveira',
        comment: 'Qualidade absurda, parabéns pelo trabalho!'
    },
    {
        name: 'Eduardo dos Santos',
        comment: 'Produto bom, entregaram no prazo.'
    },
];

const CommentCarousel = () => {
    return (
        <Carousel
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={5000}
            className={styles.commentCarousel}
            showIndicators={false}
        >
            {comments.map((comment, index) => (
                <div key={index} className={styles.carouselSlide}>
                    <h3>{comment.name}</h3>
                    <p>{comment.comment}</p>
                </div>
            ))}
        </Carousel>
    );
};

export default CommentCarousel;
